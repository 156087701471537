import { useEffect, useMemo } from 'react';
import { useLocation } from '@remix-run/react';
import { isbot } from 'isbot';

import { analytics } from '~/entry.client';
import { useAuthenticatedUserData } from '~/src/shared/hooks';

export const useTrackPage = () => {
  const location = useLocation();
  const { organisation } = useAuthenticatedUserData();
  let analyticsPayload = useMemo(() => ({}), []);

  if (organisation) {
    analyticsPayload = {
      org_name: organisation.name,
    };
  }

  useEffect(() => {
    if (isbot(navigator.userAgent)) {
      return;
    }

    void analytics.page(
      '',
      document?.title ? `Web: ${document.title.split('|').splice(2).join('|')}` : '',
      analyticsPayload,
    );
  }, [analyticsPayload, location]);
};
